.landing-container {
  overflow-x: hidden;

  .landing-content {
    width: 90%;
    max-width: 1366px;
    padding: 80px 0;
    margin: auto;
  }

  h2,
  p {
    color: var(--dark-zero);
  }

  h1 {
    font-size: 64px;
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: var(--primary-main);
  }

  p {
    font-size: 24px;
  }

  .landing-main {
    background-image: url("../../assets/landingBackground.jpg");
    height: 85vh;
    background-size: cover;
    position: relative;

    .landing-main-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .landing-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: stretch;
      padding-top: 0;
      padding-bottom: 10%;

      h1 {
        color: white;
        font-weight: 600;
        text-align: center;
      }

      .landing-box {
        width: 100%;
        display: grid;
        column-gap: 16px;

        background-color: white;
        border-radius: 12px;
        padding: 24px 32px;
        box-shadow: 0px 0px 20px 0px #8186f7;

        &.valorization {
          grid-template-columns: 1fr auto 120px;
        }
        &.search {
          grid-template-columns: 1fr auto auto 104px;
        }
      }
      .ant-btn-primary {
        width: 100%;

        &:not(:hover) {
          background-color: var(--primary-main);
          border-color: var(--primary-main);
        }
      }
    }

    button.btn-contact-us {
      position: absolute;
      bottom: 5%;
      height: auto;
      padding: 8px 24px;
      align-self: flex-end;

      color: white;
      line-height: normal;
      border-color: white;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }

  .landing-tassapp-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    align-items: center;

    h2 {
      grid-column: 1;
    }
    p {
      grid-column: 1;
      margin-right: 96px;
    }
    .landing-image {
      grid-column: 2;
      grid-row-start: 1;
      grid-row-end: 4;

      animation: from-right 1s;

      @keyframes from-right {
        from {
          transform: translateX(40%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  .landing-competitive-advantage {
    text-align: center;

    #competitive-advantage-illustration {
      position: relative;
      margin: 96px 0 64px;
      width: 100%;

      svg {
        width: 100%;
        height: auto;
      }

      p {
        font-size: 18px;
        position: absolute;
        width: 25%;
      }

      #text-1 {
        left: 7%;
        bottom: 50%;
      }

      #text-2 {
        left: 32.5%;
        top: 60%;
      }

      #text-3 {
        left: 50.5%;
        bottom: 85%;
      }

      #text-4 {
        left: 70%;
        top: 50%;
        width: 20%;
      }
    }

    h3 {
      max-width: 516px;
      margin: auto;
    }
  }

  .landing-features {
    text-align: center;

    .landing-card-grid {
      margin: 32px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      row-gap: 64px;
      column-gap: 32px;

      .feature-card {
        width: 100%;
      }
    }
  }

  .landing-how-it-works {
    h2 {
      text-align: center;
    }

    #how-it-works-illustration {
      margin: 48px 32px;
      font-size: 24px;
      height: 320px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        max-width: 300px;
      }

      svg {
        height: 80%;
        width: auto;
        margin-left: 16px;
        margin-right: 32px;
      }
    }

    .landing-info-data-card {
      max-width: 640px;
      margin: 32px auto 0;
      padding: 16px 32px;

      font-size: 16px;
      background: #ffffff;
      box-shadow: 0px 0px 24px rgba(201, 202, 235, 0.5);
      border-radius: 12px;
    }
  }

  .landing-footer {
    display: grid;
    justify-content: space-around;
    padding: 24px 64px;
    grid-template-columns: repeat(4, auto);
    row-gap: 4px;

    box-shadow: 4px 4px 20px 0px rgba(129, 134, 246, 0.2),
      5px 5px 10px 0px rgba(129, 134, 246, 0.1);
  }
}

@media (max-width: 1199px) {
  .landing-container {
    .landing-content {
      padding: 56px 0;
    }

    .landing-tassapp-data {
      max-width: 800px;
      grid-template-columns: 100%;
      row-gap: 24px;
      justify-items: center;
      text-align: center;

      p {
        margin-right: 0;
      }

      .landing-image {
        grid-column-start: 1;
        grid-row-start: 3;
      }
    }

    .landing-competitive-advantage #competitive-advantage-illustration {
      p {
        font-size: 14px;
      }
    }

    .landing-how-it-works #how-it-works-illustration {
      height: 240px;
    }
  }
}

@media (max-width: 1023px) {
  .landing-container {
    .landing-content {
      padding: 48px 0;
    }
    h1 {
      font-size: 48px;
    }

    .landing-main .landing-content {
      .landing-box {
        padding: 16px;
        column-gap: 8px;

        &.search {
          grid-template-columns: 1fr auto auto 88px;
        }
      }
    }

    .landing-features {
      .landing-card-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .landing-how-it-works #how-it-works-illustration {
      margin: 64px 0;
      font-size: 14px;
      height: 196px;
    }

    .landing-footer {
      padding: 16px 48px;
      font-size: 12px;
    }
  }
}

@media (max-width: 767px) {
  .landing-container {
    .landing-content {
      padding: 24px 0;
      width: 95%;
    }

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }

    .landing-main .landing-content {
      .landing-box {
        &.valorization,
        &.search {
          grid-template-columns: 100%;
        }
      }

      .ant-btn-primary {
        width: 120px;
        margin-top: 16px;
        height: 40px;
      }
    }

    .landing-tassapp-data {
      .landing-image {
        width: 80%;
      }
    }

    .landing-competitive-advantage {
      h2 {
        max-width: 240px;
        margin: auto;
      }
      #competitive-advantage-illustration {
        margin: 80px 0 56px;
        p {
          font-size: 9px;
        }
      }
    }

    .landing-how-it-works #how-it-works-illustration {
      margin: 24px 16px;
      font-size: 10px;
      height: 112px;

      svg {
        margin-left: 0;
        margin-right: 8px;
      }
    }

    .landing-features {
      .landing-card-grid {
        margin: 16px 0;
        display: grid;
        justify-items: center;
        row-gap: 16px;
        column-gap: 8px;
      }
    }

    .landing-how-it-works .landing-info-data-card {
      padding: 16px;
      font-size: 10px;
      margin: 0 8px;
    }

    .landing-footer {
      padding: 8px 8px;
      font-size: 8px;
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;