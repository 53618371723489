.mapboxgl-map {
  border-radius: 12px;
}

.mapbox-gl-draw_polygon {
  border-radius: 10px;
  width: 29px;
  height: 29px;
  img {
    padding-bottom: 4px;
  }
  .deleteTrashIcon {
    width: 16px;
    padding-top: 1px;
    padding-right: 2px;
  }
}
.mapboxgl-ctrl-top-right {
  margin-top: 60px;
  margin-right: 11px;
  background-color: transparent;

  .alert {
    img {
      margin-top: -30px;
      margin-left: 100px;
    }
    div {
      background-color: var(--primary-slim);
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      border-radius: 13px;
      width: 218px;
      padding-top: 7px;
      height: 35px;
      vertical-align: middle;
      line-height: 21px;
    }
  }
  .loadingIcon {
    position: absolute;
    animation: rotation 2s infinite linear;
    -webkit-animation: rotation 2s infinite linear;
    height: 33px;
    width: 33px;
    top: 30px;
    left: -145px;
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
}
.areaPopup {
  .mapboxgl-popup-tip {
    border-top-color: #c9caeb;
    display: inherit !important;
  }
  .mapboxgl-popup-content {
    z-index: 2;
    background-color: #c9caeb;
    text-align: center;
    color: var(--dark-one);
  }
  img {
    z-index: 1;
    position: absolute;
    bottom: -10px;
    left: 63px;
  }
  width: 150px;
  height: 35px;
  border-radius: 13px;
}
.mapboxgl-ctrl-bottom-right {
  .mapboxgl-ctrl-group:not(:empty) {
    border-radius: 10px;
    box-shadow: none;
    background-color: transparent;
    button {
      margin-bottom: 5px;
      border-radius: 10px;
      background-color: white;
      img {
        background-size: no-repeat;
      }
      .iconMapPin {
        content: url("../../assets/iconMapPinBlack.svg");
        width: 15px;
        height: 16px;
      }
      .iconCenterGPS {
        content: url("../../assets/iconCenterGPSBlack.svg");
        width: 20px;
        height: 20px;
      }
      .iconPlus {
        content: url("../../assets/iconPlusBlack.svg");
        width: 16px;
        height: 16px;
      }
      .iconMinus {
        content: url("../../assets/iconMinusBlack.svg");
        width: 16px;
        height: 16px;
      }
    }
    button :focus {
      border: none;
    }
    button:hover {
      border-radius: 10px;
      border: 2px solid #8186f7;
      background-color: #8186f7;
      .iconMapPin {
        content: url("../../assets/iconMapPinWhite.svg");
      }
      .iconCenterGPS {
        content: url("../../assets/iconCenterGPSWhite.svg");
      }
      .iconPlus {
        content: url("../../assets/iconPlusWhite.svg");
      }
      .iconMinus {
        content: url("../../assets/iconMinusWhite.svg");
      }
    }
    .zoomButtons :first-child {
      margin-bottom: 0;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .zoomButtons button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.mapboxgl-ctrl-group.mapbox-satelital-control {
  box-shadow: none;
}

.mapbox-satelital-control {
  position: absolute;
  bottom: 36px;
  left: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;

  .mapbox-satelital-button-group {
    width: 192px;
    height: 36px;
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    background-color: white;

    button {
      flex: 1;
      height: 100%;
      font-size: 14px;
      border-radius: 12px !important;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 4px;
        width: 14px;
        height: 14px;
        path {
          fill: var(--dark-one);
        }
      }
    }
  }

  .inactive :hover {
    background-color: var(--light-one);
  }
  .active:hover {
    background-color: var(--primary-lavander);
  }
  .active {
    &,
    &:hover {
      background-color: var(--primary-lavander) !important;
    }
  }
}

.DirectionBarContainer {
  position: absolute;
  width: 100%;
  padding: 12px;
  z-index: 90;
}
g {
  position: absolute;
}
.mapboxgl-marker {
  display: flex;
  align-items: center;
}
.selected-cluster-marker {
  font-style: normal;
  color: #fff;
  font-weight: 500;
  border: 1px solid var(--primary-lighter);
  background: var(--primary-slim);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cluster-marker {
  font-style: normal;
  color: var(--primary-main);
  font-weight: 500;
  border: 1px solid var(--primary-main);
  background: #fff;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}
.mapPin {
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
}

.mapPinValue {
  -webkit-filter: drop-shadow(0px 2px 4px #28293d);
  filter: drop-shadow(0px 2px 4px rgba(40, 41, 61, 0.16));
  position: absolute;
  background-image: url("../../assets/iconMapPriceGlobe.svg");
  background-size: cover;
  padding: 5px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  height: 34px;
  color: var(--text-primary);
  margin: -33px 50px;
  width: 110px;
  text-align: center;
}
.mapMainSatelitalPin {
  background-image: url("../../assets/iconMapPinWhite.svg");
  background-size: cover;
}
.mapMainDefaultPin {
  background-image: url("../../assets/iconMapPinGradient.svg");
  background-size: cover;
}
.mapMainPin {
  position: absolute;
  margin: -50px;
  height: 50px;
  width: 40px;
  margin-bottom: 5px;
  z-index: 0;
}
.mapPinDot {
  -webkit-filter: drop-shadow(0px 8px 16px rgba(96, 97, 112, 0.1));
  filter: drop-shadow(0px 8px 16px rgba(96, 97, 112, 0.1));
  background-image: url("../../assets/iconElipseMapPropertySmall.svg");
  background-size: cover;
  height: 12px;
  width: 12px;
  margin-bottom: 5px;
  z-index: 0;
  &--hover {
    background-image: url("../../assets/OnHoverCircle.svg");
  }
}
.markerContainer {
  .mapPinDot {
    pointer-events: none;
  }
  .mapboxgl-popup {
    top: -265px;
  }
}
.mapboxgl-popup {
  z-index: 2;
  border-radius: 15px;
  .mapboxgl-popup-tip {
    display: none;
  }
  .mapboxgl-popup-content {
    border-radius: 20px;
    padding: 0;
  }
  .propertyCard {
    border-radius: 22px;
    box-shadow: 0px 10px 20px rgba(41, 126, 255, 0.15);
    width: 200px;
    background-color: var(--white-primary);
    border: 2px solid #6e4ef6;
  }

  .propertyData {
    text-align: left;
    padding-left: 5px;
    margin-left: 5px;
    margin-right: 5px;

    h4 {
      color: #6e4ef6;
      margin: 0;
      margin-top: 7px;
      padding: 0;
      font-weight: bold;
    }
  }

  .propertyImage {
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 200px 100px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .propertyAssets {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 35px;
    color: var(--text-secondary);
    font-size: 12px;
    margin-bottom: 5px;

    img {
      margin-right: 5px;
    }
  }
}

// Map layout

.mapboxgl-ctrl-top-right,
.DirectionBarContainer {
  z-index: 1;
}

.overlays {
  overflow: visible !important;
}

@media (max-width: 767px) {
  .mapbox-satelital-control {
    bottom: 8px;
    width: 100%;
    left: 0;
    justify-content: center;
    margin: auto;

    .mapbox-satelital-button-group {
      border-radius: 8px;
      width: 160px;
      height: 24px;
      button {
        max-width: 80px;
        font-size: 10px;
        border-radius: 8px !important;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;