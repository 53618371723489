// Side menu
.user-profile-side-menu {
  padding: 8px 0;
  min-width: 280px;
  display: flex;
  flex-direction: column;

  #menu-header .ant-menu-item-group-title {
    color: var(--dark-one);
  }

  .ant-menu-item-group:not(:last-child):not(#menu-header) {
    margin-bottom: 32px;
  }

  .ant-menu-item-group-title {
    padding: 12px 32px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: var(--dark-zero);

    svg {
      height: 20px;
      width: 20px;
      margin-right: 8px;

      path {
        fill: var(--dark-zero);
      }
    }
  }

  .ant-menu-item {
    margin: 0 !important;
    height: 56px;
    padding: 0 40px;
    display: flex;
    align-items: center;

    &:hover,
    &.ant-menu-item-selected {
      svg path {
        fill: var(--primary-darker);
      }
    }
  }

  .footer-menu {
    margin-top: auto;

    .ant-menu-item-group-title {
      display: none;
    }

    .ant-menu-item {
      padding: 0 32px;
    }
  }
}

.user-profile-view {
  display: flex;
  height: 100%;

  .user-profile-data-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    align-items: stretch;
    padding: 64px 64px 0;
    max-width: 1200px;
    margin: 0 auto;

    .user-profile-data-profile {
      display: grid;
      grid-template-columns: 160px repeat(3, 1fr);
      grid-template-rows: 1fr auto;
      column-gap: 48px;

      .user-profile-data-avatar {
        grid-row-end: span 2;
      }

      h2 {
        grid-column-end: span 3;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 18px;
      }
      span {
        height: fit-content;
        font-size: 15px;
        align-self: end;
        color: var(--dark-three);
        em {
          color: var(--dark-one);
          font-style: normal;
        }
      }
    }
    .user-profile-data-cards {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 180px);
      column-gap: 32px;
      justify-content: center;
      align-content: space-evenly;
    }
  }

  .user-profile-data-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    align-items: stretch;
    padding: 64px 64px 0;
    max-width: 1200px;
    margin: 0 auto;

    .user-profile-data-profile {
      display: grid;
      grid-template-columns: 160px repeat(3, 1fr);
      grid-template-rows: 1fr auto;
      column-gap: 48px;

      .user-profile-data-avatar {
        grid-row-end: span 2;
      }

      h2 {
        grid-column-end: span 3;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 18px;
      }
      span {
        height: fit-content;
        font-size: 15px;
        align-self: end;
        color: var(--dark-three);
        em {
          color: var(--dark-one);
          font-style: normal;
        }
      }
    }
    .user-profile-data-cards {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 180px);
      column-gap: 32px;
      justify-content: center;
      align-content: space-evenly;
    }
  }

  .user-profile-appraisals-view {
    width: 100%;
    padding: 0 32px;
    overflow-y: auto;

    .anticon path {
      fill: var(--dark-one);
    }

    .massive-appraisals-select-menu {
      margin-top: 24px;
      display: flex;
      width: 90%;
      margin-left: auto;
      box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4),
        0px 2px 4px rgba(201, 202, 235, 0.4);
      border-radius: 12px;
      align-items: center;
      justify-content: space-between;
      padding: 4px 16px;

      .actions {
        width: 20%;
        display: flex;
        justify-content: space-evenly;
        .ant-btn {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .user-profile-side-menu {
    min-width: unset;
    border: none;
  }

  .user-profile-view .user-profile-data-view {
    .user-profile-data-cards {
      display: grid;
      grid-template-columns: 640px;
      grid-template-rows: repeat(4, 200px);
    }
  }
}

@media (max-width: 1023px) {
  .user-profile-view {
    .user-profile-data-view {
      .user-profile-data-cards {
        grid-template-columns: 480px;
        grid-template-rows: repeat(4, 140px);
      }
    }

    .user-profile-appraisals-view {
      padding: 0 0 16px;
    }
  }
}

@media (max-width: 767px) {
  .user-profile-view {
    .user-profile-data-view {
      padding: 8px 16px 0;
      align-items: center;

      .user-profile-data-profile {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(4, auto);
        column-gap: 48px;
        padding: 8px;

        h2 {
          font-size: 16px;
          grid-row-start: 1;
          grid-column-end: span 2;
        }

        .user-profile-data-avatar {
          width: 120px;
          height: 120px;
          grid-row-start: 2;
          grid-row-end: span 3;
          grid-column-start: 1;
        }

        span {
          grid-column-start: 2;
          font-size: 12px;
          align-self: center;
        }
      }
      .user-profile-data-cards {
        grid-template-columns: 1fr;
        gap: 8px;
        grid-template-rows: repeat(4, 88px);

        .data-card-container {
          min-height: unset;
          .anticon {
            font-size: 24px;
          }
          h3 {
            font-size: 13px;
          }
          .data-card-footer {
            font-size: 12px;
          }
        }
      }
    }

    .user-profile-appraisals-view {
      padding: 0;

      &.massive {
        .massive-appraisals-select-menu {
          margin-top: 8px;
          display: flex;
          width: 100%;
          border-radius: 0;
          align-items: center;
          justify-content: space-between;
          padding: 4px 8px;

          font-size: 11px;

          .actions {
            width: 20%;
            display: flex;
            justify-content: space-evenly;
            .ant-btn {
              border: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;